<template>
    <div>
        <b-card>
            <h4>{{title}}</h4>
        </b-card>
        <b-row>
            <b-col v-for="(item,index) in statisticsItems" :key="index" md="4" sm="6">
                <statistic-card-vertical :icon="item.icon" :statistic="item.title + ' EGP'" :statistic-title="item.subtitle" :color="item.color" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
    import {
        kFormatter
    } from '@core/utils/filter'

    
    export default {
        components: {
            StatisticCardVertical,
        },
        props: ['statisticsItems', 'title'],
        data() {
            return {
                // statisticsItems: [
                //   {
                //     icon: 'TrendingUpIcon',
                //     color: 'light-primary',
                //     title: '230k',
                //     subtitle: 'Sales',
                //     customClass: 'mb-2 mb-xl-0',
                //   },
                //   {
                //     icon: 'UserIcon',
                //     color: 'light-info',
                //     title: '8.549k',
                //     subtitle: 'Customers',
                //     customClass: 'mb-2 mb-xl-0',
                //   },
                //   {
                //     icon: 'BoxIcon',
                //     color: 'light-danger',
                //     title: '1.423k',
                //     subtitle: 'Products',
                //     customClass: 'mb-2 mb-sm-0',
                //   },
                //   {
                //     icon: 'DollarSignIcon',
                //     color: 'light-success',
                //     title: '$9745',
                //     subtitle: 'Revenue',
                //     customClass: '',
                //   },
                // ],
            }
        },
        methods: {
            kFormatter,
        },
    }
</script>
