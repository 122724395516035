<template>
  <div>
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-row>
      <b-col cols="12">
        <card-analytic-company-financial-account
          title="Company Financial Account"
          :statisticsItems="totalSale"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CardAnalyticCompanyFinancialAccount from './Analytics/CardAnalyticCompanyFinancialAccount.vue'

export default {
  components: {
    CardAnalyticCompanyFinancialAccount,
  },

  data() {
    return {
      errors_back: [],
      showDismissibleAlert: false,
      totalSale: [],
      commissionAmount: [],
      receivedAmount: [],
      remainingAmount: [],
      offerAmount: [],
      bundleOffer: [],
      financialAccount: [],
    }
  },
  created() {
    axios
      .get('account/show')
      .then((result) => {
        const data = result.data.data

        for (let index in data) {
          this.totalSale.push({
            icon: 'DollarSignIcon',
            color: 'primary',
            title: data[index].value,
            subtitle: this.globalHelper(data[index].attribute),
            customClass: 'mb-2 mb-xl-0',
          })
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
    

<style>
</style>
